/* eslint-disable react/no-unstable-nested-components */
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthenticationContext } from 'containers/auth';
import { personalityInfo } from '../../utils/helpers';
import styles from './styles';

const PersonalityType = ({ flavor, investorName, score }) => {
  const { user } = useContext(AuthenticationContext);

  const companyRiskLevels = user?.advisor?.company?.risk_levels;

  const { reportIcon: ReportIcon, ...personality } = personalityInfo(score, companyRiskLevels);

  const level = Math.ceil(score);

  return (
    <View style={styles.container}>
      <ReportIcon style={styles.badge} />
      <Text style={styles.personalityType}>
        {flavor === 'investor' ? 'You are' : `${investorName} is`}{' '}
        <Text style={[styles[`levelColor${level}`], { fontWeight: 'bold' }]}>
          {personality.label}
        </Text>
      </Text>
      <Text>
        <FormattedMessage
          id="score.personality.meaning"
          values={{
            bold: str => <Text style={{ fontWeight: 'bold' }}>{str}</Text>,
            flavor,
            investorName,
            maxRange: personality.max,
            minRange: personality.min,
            meaning: personality.description
          }}
        />
      </Text>
    </View>
  );
};

PersonalityType.propTypes = {
  flavor: PropTypes.string.isRequired,
  investorName: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired
};

export default PersonalityType;
